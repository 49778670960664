import { CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import { VerifiedOutlined } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Appcues from 'utils/appcues'

import { Button } from 'components/design-system'

import useStyles from '../style'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { editStatusControl } from 'services/activity'
import { ActivityControlStatusEnum, IRecordStatusControlRequest } from 'services/types'
import { toast } from 'components/design-system/Toast/manager'
import { useStore } from 'store'

const ButtonFixCorrect: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { schoolPeriod } = useStore()

  // params
  const { classId } = useParams<{ classId: string }>()

  // atom
  const [activity, setActivity] = useAtom(activityCurrentAtom)

  // state
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)

  const isExistsNotRevised = activity?.questions?.some(question => !question.all_revised_or_corrected)

  const changeActivityStatus = async () => {
    if (isLoadingRequest) return
    setIsLoadingRequest(true)

    Appcues.track('prof_correcao_atividade')

    const data: IRecordStatusControlRequest = {
      activityId: activity?.id ?? 0,
      activityStatus: ActivityControlStatusEnum.RELEASED
    }
    const result = await editStatusControl(classId, data)

    if (result.success) {
      activity && setActivity({ ...activity, activity_status: { has_answer: activity?.activity_status?.has_answer ?? false, status: ActivityControlStatusEnum.RELEASED } })
      toast.handler({
        content: t('Correções liberadas com sucesso'),
        duration: 10000,
        severity: 'success'
      })
      setIsLoadingRequest(false)
      return
    }

    toast.handler({
      content: t('Erro na liberação das correções. Tente novamente.'),
      duration: 10000,
      severity: 'error'
    })

    setIsLoadingRequest(false)
  }

  if (activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate)) && !isExistsNotRevised && Number(activity?.activity_status?.status) !== ActivityControlStatusEnum.RELEASED) {
    return (
      <Button
        data-testid='correction_activity_menu_answers_release_correction'
        size='small'
        variant='primary'
        startIcon={<VerifiedOutlined />}
        fullWidth={isMobile}
        className={classes.questionSelectorButtonsHandles}
        onClick={changeActivityStatus}
        disabled={!schoolPeriod?.current}
      >
        {isLoadingRequest ? <CircularProgress size={20} /> : 'Liberar correções'}
      </Button>
    )
  }

  if (Number(activity?.activity_status?.status) === ActivityControlStatusEnum.RELEASED) {
    return (
      <Button
        size='small'
        variant='outlined'
        startIcon={<VerifiedOutlined />}
        fullWidth={isMobile}
        className={classes.questionSelectorButtonsHandles}
        disabled
      >
        Correções liberadas
      </Button>
    )
  }

  return (
    <Button
      size='small'
      variant='outlined'
      startIcon={<VerifiedOutlined />}
      fullWidth={isMobile}
      className={classes.questionSelectorButtonsHandles}
      disabled
    >
      Liberar correções
    </Button>
  )
}

export default ButtonFixCorrect
