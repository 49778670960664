import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'

import { Grid, useTheme } from '@mui/material'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { Text, Button } from 'components/design-system'
import { ScheduleCard } from 'components/common'
import BackToTopWindow from 'components/common/BackToTopWindow'
import { BusinessError } from 'navigation/BusinessError'

import { LoadingState, LoadMoreErrorState, NotFoundState, Ready } from './components'

import { useCurricularStore, useStore } from 'store'

import { getPendentActivitiesStudent, getPendentActivitiesTeacher } from 'services/schedule'
import { IScheduleActivitiesPendentResponse, IUserSchoolProfileTypeEnum } from 'services/types'

import useStyle from './style'
import { DASHBOARD_TEACHER, STUDENT_SCHEDULE } from 'navigation/CONSTANTS'
import { Disciplines } from 'components/store'

enum PageStateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  LOAD_MORE_ERROR = 'LOAD_MORE_ERROR'
}

export const PendingActivities = () => {
  const { profile } = useStore()
  const [pageStateMachine, setPageStateMachine] = useState<PageStateMachineEnum>(PageStateMachineEnum.IDLE)
  const [pendentActivities, setPendentActivities] = useState<IScheduleActivitiesPendentResponse[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState<number | null>(null)
  const cardsInnerSpace = 24
  const cardItemWidth = 300 + cardsInnerSpace

  const classes = useStyle()
  const theme = useTheme()

  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  const { currentDiscipline } = useCurricularStore()

  const { t } = useTranslation()

  const cardsContainerWidthRef = useCallback(node => {
    if (node !== null) {
      const cardsContainerWidth = node.getBoundingClientRect().width
      const cardsOnPage = Math.max(
        Math.floor(cardsContainerWidth / cardItemWidth) * 2,
        20
      ) // minimun of 20 cards or 2 rows

      setPageSize(cardsOnPage)
    }
  }, [])

  useEffect(() => {
    void reset()
  }, [currentDiscipline, pageSize])

  const fetchPendentActivities = async (pageCount: number) => {
    if (!pageSize) {
      return
    }

    setPageStateMachine(PageStateMachineEnum.LOADING)

    const pagination = pageCount * pageSize

    const response = profile?.type === IUserSchoolProfileTypeEnum.teacher
      ? await getPendentActivitiesTeacher({
        classId,
        limit: pageSize + 1,
        offset: pagination,
        disciplineId: currentDiscipline?.id
      })
      : await getPendentActivitiesStudent({
        classId,
        limit: pageSize + 1,
        offset: pagination,
        disciplineId: currentDiscipline?.id
      })

    if (!response.success) {
      setPageStateMachine(
        pageCount === 0
          ? PageStateMachineEnum.ERROR
          : PageStateMachineEnum.LOAD_MORE_ERROR
      )
      const errorMessage = t(
        response.message ??
        'Não foi possível carregar mais atividades pendentes'
      )
      setErrorMessage(errorMessage)
      return
    }

    if (response.data.length > pageSize) {
      setPageStateMachine(PageStateMachineEnum.READY)
    } else {
      setPageStateMachine(PageStateMachineEnum.IDLE)
    }

    if (!response.data.length) {
      if (pageSize === 0) {
        setPageStateMachine(PageStateMachineEnum.NOT_FOUND)
      } else {
        setPageStateMachine(PageStateMachineEnum.IDLE)
      }
      return
    }

    if (response.data.length > pageSize) {
      response.data.pop()
    }

    setPendentActivities(oldCards => [...oldCards, ...response.data])
  }

  const reset = async () => {
    setPendentActivities([])
    setPage(0)
    await fetchPendentActivities(0)
  }

  const tryAgain = async () => {
    await fetchPendentActivities(page)
  }

  const loadMore = async () => {
    setPage(oldPage => {
      fetchPendentActivities(oldPage + 1)
      return oldPage + 1
    })
  }

  const states = {
    IDLE: '',
    LOADING: <LoadingState />,
    READY: <Ready loadMore={loadMore} />,
    NOT_FOUND: <NotFoundState />,
    ERROR: (
      <Grid item sx={{ flex: 1 }}>
        <BusinessError error={errorMessage ?? ''}>
          <Button
            className={classes.button}
            variant='primary'
            type='submit'
            onClick={async () => await reset()}
          >
            {t('Tentar novamente')}
          </Button>
        </BusinessError>
      </Grid>
    ),
    LOAD_MORE_ERROR: <LoadMoreErrorState tryAgain={tryAgain} />
  }

  const renderMachineState = () => {
    return states[pageStateMachine]
  }

  const gotBackDataTestId = profile?.type === IUserSchoolProfileTypeEnum.teacher ? 'activities_to_correct_back' : 'activities_to_answer_back'
  const disciplineDataTestId = profile?.type === IUserSchoolProfileTypeEnum.teacher ? 'pending_activities_curricular_component' : 'schedule_curricular_component'
  const returnLink = profile?.type === IUserSchoolProfileTypeEnum.teacher ? DASHBOARD_TEACHER(gradeTypeCode, gradeCode, classId) : STUDENT_SCHEDULE
  const headerTitle = profile?.type === IUserSchoolProfileTypeEnum.teacher ? 'Para corrigir' : 'Para responder'

  return (
    <Grid container padding={3} spacing={1}>
      <Grid
        ref={cardsContainerWidthRef}
        item
        xs={12}
        display='flex'
        alignItems='center'
      >
        <NavLink
          to={returnLink}
          className={classes.backLink}
          data-testid={gotBackDataTestId}
        >
          <ArrowBackIosNewOutlined />
          <Text>{t('Voltar')}</Text>
        </NavLink>
      </Grid>
      <Grid item xs={12}>
        <Text
          size='large'
          style={{
            color: theme.colors.neutral.dark20,
            fontWeight: theme.font.fontWeight.semibold
          }}
        >
          {t(headerTitle)}
        </Text>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Disciplines dataTestid={disciplineDataTestId} showAll />
      </Grid>
      <Grid item container direction='row' spacing={3} sx={{ marginTop: 0 }}>
        {pendentActivities?.map((card, index) => (
          <Grid key={index} item className={classes.scheduleCard}>
            <ScheduleCard
              activitiesPendents={card}
              type={card.suggestedApplicationType}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{ py: 3 }}
      >
        {renderMachineState()}
      </Grid>
      <BackToTopWindow />
    </Grid>
  )
}

export default PendingActivities
