import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtomValue } from 'jotai/utils'
import dayjs from 'dayjs'
import SendIcon from '@mui/icons-material/Send'
import { CircularProgress, Grid, useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import { toast } from 'components/design-system/Toast/manager'
import {
  scheduleRequestAtom,
  isHoursAndMinutesNowAtom,
  isInvalidStartDateAtom,
  selectedResourcesAtom,
  selectedArticlesAtom,
  isValidStartDateTimeAtom
} from '../LibraryContentShare/atomStore'
import { useOnlineStatus } from 'contentCacheManager'
import useMyCloeSchedule from 'navigation/hooks/useMyCloeSchedule'
import { IScheduleRequest } from 'services/types/schedule'
import { useStore } from 'store'
import Analytics from 'utils/analytics'

interface IProps {
  onSuccess: () => void
  onCancel: () => void
  dataTestId?: {
    cancel_creation: string
    create: string
  }
  dataTestIdOnSuccess?: string
  dataTestIdOnError?: string
}

export const SendSchedule: React.FC<IProps> = ({ onSuccess, onCancel, dataTestId, dataTestIdOnSuccess, dataTestIdOnError }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { sendSchedule } = useMyCloeSchedule()

  // state
  const [loading, setLoading] = useState<boolean>(false)

  // atom
  const isStartDateInvalid = useAtomValue(isInvalidStartDateAtom)
  const isHoursAndMinutesNow = useAtomValue(isHoursAndMinutesNowAtom)
  const resourcesSelected = useAtomValue(selectedResourcesAtom)
  const articlesSelected = useAtomValue(selectedArticlesAtom)
  const schedule = useAtomValue(scheduleRequestAtom)
  const isValidStartDateTime = useAtomValue(isValidStartDateTimeAtom)

  const { profile, subscription } = useStore()

  const isOnline = useOnlineStatus()

  const defaultVisionDataTestIds = {
    cancel_creation: 'event_without_expedition_cancel_event_creation',
    create: 'event_without_expedition_create_event'
  }

  const dataTestIds = dataTestId ?? defaultVisionDataTestIds

  const handleSubmit = async () => {
    let scheduleStartDate
    setLoading(true)

    // to remove seconds
    const dateFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }
    if (isHoursAndMinutesNow) {
      scheduleStartDate = 'now'
    } else {
      scheduleStartDate = dayjs(schedule?.start_date)
      scheduleStartDate = `${(scheduleStartDate.toDate()).toLocaleString('sv', dateFormat)}:00${scheduleStartDate.format('Z')}`
    }

    const scheduleRequest: IScheduleRequest = {
      class: schedule.class ?? 0,
      content: schedule?.content?.trim() ?? null,
      discipline: schedule?.discipline ?? null,
      start_date: scheduleStartDate ?? null,
      type: 'library_content',
      resources: resourcesSelected.map(r => r.resourceId),
      articles: articlesSelected.map(a => ({
        title: a.title,
        id: a.articleId
      })),
    }

    let validationError = true
    if (!schedule?.content) {
      toast.handler({
        content: t('É necessário descrição nesse evento.'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (isStartDateInvalid) {
      toast.handler({
        content: t('As datas devem seguir o formato DD/MM'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (!isOnline) {
      toast.handler({
        content: t('Algo deu errado e não foi possível criar o seu evento. Tente novamente. Código: OFFLINE_CONNECTION'),
        duration: 5000,
        severity: 'error'
      })
    } else {
      validationError = false
    }

    if (validationError) {
      setLoading(false)
      return
    }

    // TODO: verifica se é atualização

    const response = await sendSchedule(scheduleRequest, '', false, false)
    if (response) {
      onSuccess()
      // TODO: adicionar clique "Ir para agenda" e trocar icone
      toast.handler({
        content: t('Evento(s) criado(s) na agenda.'),
        duration: 5000,
        severity: 'success'
      })

      if (dataTestIdOnSuccess) {
        Analytics.recordEventClick({
          name: dataTestIdOnSuccess,
          attributes: {
            ...profile?.analytics,
            ...subscription?.analytics
          }
        })
      }

    } else {
      if (dataTestIdOnError) {
        Analytics.recordEventClick({
          name: dataTestIdOnError,
          attributes: {
            ...profile?.analytics,
            ...subscription?.analytics
          }
        })
      }
    }
    setLoading(false)
  }

  const isSendButtonDisabled = (
    !isValidStartDateTime ||
    loading || !schedule?.discipline ||
    (!schedule?.start_date && !isHoursAndMinutesNow) ||
    !schedule.content || !schedule.class ||
    (!resourcesSelected.length && !articlesSelected.length)
  )

  return (
    <Grid
      container
      spacing={2}
      p={2}
    >
      <Grid item xs={6} justifySelf='flex-start'>
        <Button
          variant='outlined'
          sx={{
            padding: '8px 12px !important',
            gap: '8px',
            fontWeight: `${theme.font.fontWeight.medium} !important`,
            width: '100%'
          }}
          onClick={onCancel}
          data-testid={dataTestIds.cancel_creation}
        >
          {t('Cancelar')}
        </Button>
      </Grid>
      <Grid item xs={6} justifySelf='flex-end'>
        <Button
          sx={{
            padding: '8px 12px !important',
            gap: '8px',
            fontWeight: `${theme.font.fontWeight.medium} !important`,
            width: '100%'
          }}
          onClick={handleSubmit}
          disabled={isSendButtonDisabled}
          data-testid={dataTestIds.create}
        >
          {
            !loading
              ? <>
                {t('Enviar')}
                <SendIcon data-testid={dataTestIds.create} />
              </>
              : <CircularProgress
                sx={{
                  color: 'white',
                  height: '30px !important',
                  width: '30px !important'
                }}
              />
          }
        </Button>
      </Grid>
    </Grid>
  )
}
