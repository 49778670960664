import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { Grid } from '@mui/material'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { Tab, Tabs } from 'components/design-system'
import { MY_CLASSES } from 'navigation/CONSTANTS'

import { tabIndexAtom } from '../atomStore'
import { useStore } from 'store'

import Appcues from 'utils/appcues'

import useStyle from './style'

interface IHeaderProps {
  theClassName: string
  gradeName: string
}
interface IHeaderTabsProps {
  className: string
}

export const HeaderTabs: React.FC<IHeaderTabsProps> = ({ className }) => {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useAtom(tabIndexAtom)
  const classes = useStyle()
  const { session } = useStore()
  const { classId } = useParams<{ classId: string }>()

  const classSelected = session?.subscriptions?.filter(cur => cur.class).find(sub => sub?.class?.id?.toString() === classId)?.class

  return (
    <Tabs value={parseInt(tabIndex.toString())} variant='scrollable' scrollButtons={false} onChange={(e, index) => setTabIndex(parseInt(index))} className={`${classes.tabs} ${className}`}>
      <Tab label={t('Agenda')} data-testid='class_menu_schedule' />
      <Tab label={t('Conteúdos')} data-testid='class_menu_content' onClick={() => { Appcues.track('prof_conteudo') }} />
      {!classSelected?.grades[0]?.code?.includes('EI_') && (
        <Tab label={t('Acompanhamento')} data-testid='class_menu_followup' onClick={() => Appcues.track('prof_acompanhamento')} />
      )}
      <Tab label={t('Registros da turma')} data-testid='class_menu_class_records' onClick={() => Appcues.track('prof_registro_turma')} />
    </Tabs>
  )
}

export const Header: React.FC<IHeaderProps> = ({ theClassName, gradeName }) => {
  // style
  const classes = useStyle()
  const [, setTabIndex] = useAtom(tabIndexAtom)

  const handleReturn = () => {
    setTabIndex(0)
  }

  return (
    <Grid item container xs={12} className={classes.headerContainer}>
      <Grid item xs={12} md={3}>
        <NavLink to={MY_CLASSES} className={classes.backLink} onClick={handleReturn} data-testid='class_menu_back_to_my_classes'>
          <ArrowBackIosNewOutlined /> {gradeName !== '' ? `${gradeName} - ` : ''} {theClassName}
        </NavLink>
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        className={classes.tabsContainer}
      >
        <HeaderTabs className='only-desktop' />
      </Grid>
    </Grid>
  )
}

export default Header
