import { CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import { StarBorderOutlined } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/design-system'

import useStyles from '../style'
import { revisionAllAnswers } from 'services/answer'
import { activeQuestionAtom, getStudentsAnswerByQuestionAtom } from '../../../atomStore'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { toast } from 'components/design-system/Toast/manager'
import { getActivityByClass } from 'services/activity'
import { useStore } from 'store'

const ButtonRevised: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { schoolPeriod } = useStore()

  // state
  const [isRevisionAllLoading, setIsRevisionAllLoading] = useState(false)

  // atom
  const [activity, setActivity] = useAtom(activityCurrentAtom)
  const [activeQuestion] = useAtom(activeQuestionAtom)
  const [, getStudentsAnswerByQuestion] = useAtom(getStudentsAnswerByQuestionAtom)

  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  const isExistsNotRevised = activity?.questions?.some(question => !question.all_revised_or_corrected)

  const handleRevisedAllAnswers = async () => {
    if (isRevisionAllLoading) return
    setIsRevisionAllLoading(true)
    const response = await revisionAllAnswers({ classId, activityId })
    await getStudentsAnswerByQuestion({ classId, activityId: activityId ?? evaluationId, questionId: activeQuestion })

    const responseActivity = await getActivityByClass(classId, activityId ?? evaluationId)
    activity && setActivity({ ...activity, questions: responseActivity?.data?.questions })

    if (response.success) {
      toast.handler({
        content: t('Visto realizado com sucesso'),
        duration: 10000,
        severity: 'success'
      })
      return
    }

    toast.handler({
      content: t('Falhar ao tentar revisar a questão. Tente novamente'),
      duration: 10000,
      severity: 'error'
    })
    setIsRevisionAllLoading(false)
  }

  if (activity?.suggested_application_type === 'evaluation') return <></>

  if (activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate))) {
    if (!isExistsNotRevised) {
      return (
        <Button
          size='small'
          variant='outlined'
          startIcon={<StarBorderOutlined />}
          fullWidth={isMobile}
          disabled
          className={classes.questionSelectorButtonOutlinedDisabled}
        >
          Respostas vistadas
        </Button>
      )
    }

    return (
      <Button
        data-testid='correction_activity_menu_answers_check_all_questions'
        size='small'
        variant='primary'
        startIcon={<StarBorderOutlined />}
        fullWidth={isMobile}
        className={classes.questionSelectorButtonsHandles}
        disabled={!schoolPeriod?.current}
        onClick={handleRevisedAllAnswers}
      >
        {isRevisionAllLoading ? <CircularProgress size={20} /> : 'Vistar tudo'}
      </Button>
    )
  }

  return (
    <Button
      size='small'
      variant='outlined'
      startIcon={<StarBorderOutlined />}
      fullWidth={isMobile}
      disabled
      className={classes.questionSelectorButtonsHandles}
    >
      Vistar tudo
    </Button>
  )
}

export default ButtonRevised
