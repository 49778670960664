import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IMyCloe {
  openMyCloe: boolean
}

export default makeStyles((theme: Theme) => createStyles({
  content: {
    transition: 'all ease-in-out 0.2s',
    zIndex: 1000,
    backgroundColor: theme.colors.neutral.lightBase,
    height: '100vh',
    width: ({ openMyCloe }: IMyCloe) => openMyCloe ? '334px' : '1px',
    right: '0',
    top: '77px',
    marginRight: '0',
    position: 'fixed'
  },
  top: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    position: 'absolute',
    height: '30px',
    opacity: ({ openMyCloe }: IMyCloe) => openMyCloe ? '100%' : '0',
    width: '30px',
    overflow: 'hidden',
    transition: 'all ease-in-out 0.2s',
    top: '-28px',
    right: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      height: '200%',
      width: '200%',
      borderRadius: '100%',
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.medium}`,
      zIndex: 0,
      top: '-100%'
    }
  },
  box: {
    position: 'fixed',
    zIndex: 10,
    left: 0,
    height: 'auto',
    width: 67,
    backgroundColor: theme.colorBrand.medium,
    borderRadius: '0 32px 32px 0',
    textAlign: 'center',
    color: theme.colors.neutral.lightBase,
    transition: 'all ease-in-out 0.2s'
  },
  buttonWrapper: {
    position: 'absolute',
    background: theme.colorBrand.medium,
    left: 0,
    width: '100%',
    zIndex: -1
  },
  childrenWrapper: {
    boxShadow: '0px 16px 56px rgba(20, 20, 20, 0.16)',
    opacity: ({ openMyCloe }: IMyCloe) => openMyCloe ? '100%' : '0',
    overflowY: 'auto',
    height: 'calc(100% - 140px)',
    marginTop: '52px',
    background: '#F5F3F8',
    '& > div': {
      background: '#F5F3F8'
    }
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',

    position: 'relative',
    right: '34px',
    borderRadius: '16px 0px 0px 16px',
    background: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,
    border: 'unset',
    cursor: 'pointer',
    padding: '16px 8px',

    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: '16px',
    letterSpacing: '0.12px',

    // mobile
    [theme.breakpoints.down('sm')]: {
      borderLeft: '1px solid white',
      height: '52px',
      width: '28px',
      right: '24px'
    }
  }
}))
