import { Box, useTheme } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Icon, Text } from 'components/design-system'
import Loading from 'components/common/Loading'

import useStyle, { IModalWrapperStyleProps } from './style'
import { useTranslation } from 'react-i18next'

interface IModalWrapperProps {
  open: boolean
  confirmLabel?: string
  cancelLabel?: string
  onCancel?: () => void
  onConfirm: () => void
  confirmLoading?: boolean
  confirmDatatestId?: string
  confirmDisabled?: boolean
  cancelDatatestId?: string
  closeDatatestId?: string
  maxWidth?: DialogProps['maxWidth']
  showCloseIcon?: boolean
  footer?: IModalWrapperStyleProps['footer']
  customClassName?: string
  fullscreen?: boolean
  showFooter?: boolean
}

export const ModalWrapper: React.FC<IModalWrapperProps> = ({
  open,
  onCancel,
  onConfirm,
  children,
  confirmDatatestId,
  confirmDisabled,
  confirmLoading,
  cancelDatatestId,
  confirmLabel,
  cancelLabel,
  closeDatatestId,
  showCloseIcon = true,
  maxWidth,
  footer,
  customClassName,
  fullscreen,
  showFooter = true
}) => {
  const classes = useStyle({ footer })
  const theme = useTheme()
  const { t } = useTranslation()

  const handleCancel = () => {
    if (!onCancel) {
      onConfirm()
      return
    }
    onCancel()
  }
  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <Dialog
      fullWidth
      onClose={handleCancel}
      open={open}
      className={`${classes.modalContainer} ${customClassName}`}
      maxWidth={maxWidth ?? 'sm'}
      scroll='paper'
      fullScreen={fullscreen}
    >
      {showCloseIcon && (
        <Box className={classes.modalHeader}>
          <Box className={classes.closeContainer} onClick={handleCancel} data-testid={closeDatatestId ?? cancelDatatestId}>
            <Icon titleAccess={t('Ícone de Fechar')} size='medium' iconColor={theme.colorBrand.medium}>
              <CloseIcon />
            </Icon>
            <Text className={classes.headerText}>
              {t('Fechar')}
            </Text>
          </Box>
        </Box>
      )}
      <DialogContent className={classes.modalBody}>
        <>{children}</>
      </DialogContent>
      {showFooter && (
        <DialogActions className={classes.modalFooter}>
          {!!onCancel && (
            <Button
              variant='ghost'
              onClick={handleCancel}
              data-testid={cancelDatatestId}
            >
              {cancelLabel ?? t('Cancelar')}
            </Button>
          )}
          <Button
            className={classes.confirmButton}
            disabled={confirmDisabled}
            variant='primary'
            onClick={handleConfirm}
            data-testid={confirmDatatestId}
          >
            {confirmLoading
              ? <Loading type='circular' />
              : confirmLabel ?? t('Confirmar')
            }
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
export default ModalWrapper
