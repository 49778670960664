import { Dialog, useTheme } from '@mui/material'
import useStyles from './style'
import { Button, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { Loading, ModalWrapper } from 'components/common'
import { useEffect, useState } from 'react'
import { EXPORT_CLASS_PLAN } from 'services/classPlan/CONSTANTS'
import { IClassPlanItemPage } from 'services/types'
import { useStore } from 'store'
import Analytics from 'utils/analytics'

interface IExportClassPlanModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  classPlan?: IClassPlanItemPage
}

export const ExportClassPlanModal = ({ isOpen = false, setIsOpen, classPlan }: IExportClassPlanModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { profile, subscription } = useStore()
  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const handleExportClassPlan = async (option: 'Docx' | 'Pdf') => {
    if (!classPlan) return

    setIsLoading(true)

    window.open(EXPORT_CLASS_PLAN(classPlan.id, option), "_blank")

    Analytics.recordEventClick({
      name: option === 'Docx' ? 'cloe_super_lesson_plan_download_success_docx' : 'cloe_super_lesson_plan_download_success_pdf',
      attributes: {
        ...profile?.analytics,
        ...subscription?.analytics
      }
    })

    setIsOpen(false)
    setIsLoading(false)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  if (!classPlan) return <></>

  return (
    <ModalWrapper
      open={isOpen}
      onCancel={onClose}
      onConfirm={() => { }}
      footer={{
        background: theme.colors.neutral.light10
      }}
      showCloseIcon
      maxWidth={'sm'}
      showFooter={false}
    >
      <div className={classes.modalBody}>
        <div className={classes.infoContainer}>
          <Text2 fontSize='lg' mobile='lg' fontWeight='medium' colorbrand='medium'>
            {t('Qual deve ser o formato de exportação do seu plano de aula?')}
          </Text2>
        </div>
        <div className={classes.loadingContainer}>

          {isLoading
            ? (
              <Loading type='linear' className={classes.loading} />
            )
            : (
              <>
                <Button
                  variant='primary'
                  className={classes.button}
                  onClick={async () => await handleExportClassPlan('Docx')}
                  data-testid='cloe_super_lesson_plan_export_docx'
                >
                  {t('DOCX')}
                </Button>
                <Button
                  variant='primary'
                  className={classes.button}
                  onClick={async () => await handleExportClassPlan('Pdf')}
                  data-testid='cloe_super_lesson_plan_export_pdf'
                >
                  {t('PDF')}
                </Button>
              </>
            )}
        </div>
      </div>
    </ModalWrapper>
  )
}
