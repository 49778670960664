import { atom } from 'jotai'
import { _openMyCloeAtom } from 'navigation/components/MyCloe/atomStore'
import { IClassResponse, IDisciplineResponse } from 'services/types'
import { IArticleResponse, IResourceResponse } from 'services/types/library'
import { myCloeLibraryContentTabIndexAtom } from '../../atomStore'

interface IScheduleRequestAtom {
  class: number | null
  discipline: number | null
  start_date: Date | 'now'
  content: string | null
}

const initialRequest: IScheduleRequestAtom = {
  class: null,
  discipline: null,
  start_date: new Date(),
  content: null
}

export const scheduleRequestAtom = atom<IScheduleRequestAtom>(initialRequest)
export const _selectedResourcesAtom = atom<IResourceResponse[]>([])
export const _selectedArticlesAtom = atom<IArticleResponse[]>([])
export const classSelectedAtom = atom<IClassResponse | undefined>(undefined)
export const disciplinesAtom =
  atom<IDisciplineResponse[] | undefined>(undefined)
export const disciplineSelectedAtom =
  atom<IDisciplineResponse | undefined>(undefined)

export const isHoursAndMinutesNowAtom = atom(true)
export const isInvalidStartDateAtom = atom(false)

export const isValidStartDateTimeAtom = atom(true)
export const isValidEndDateTimeAtom = atom(true)

export const updateStartDateAtom = atom(
  null,
  (get, set, newStartDate: Date | undefined | 'now') => {
    const prev = get(scheduleRequestAtom)
    const newScheduleObject = {
      ...prev,
      start_date:
        newStartDate === 'now' ? new Date() : newStartDate ?? new Date()
    }
    set(scheduleRequestAtom, newScheduleObject)
  }
)

type UpdateScheduleRequestAtomActions =
  | { type: 'UPDATE'; payload: { key: string; value: any } }
  | { type: 'RESET'; payload?: undefined }

export const updateScheduleRequestAtom = atom(
  get => get(scheduleRequestAtom),
  (get, set, action: UpdateScheduleRequestAtomActions) => {
    const scheduleRequest = get(scheduleRequestAtom)
    switch (action.type) {
      case 'RESET':
        // used to reset the atom when cancel on MyCloe
        set(scheduleRequestAtom, {
          ...initialRequest
        })
        set(_selectedResourcesAtom, [])
        set(_selectedArticlesAtom, [])
        set(classSelectedAtom, undefined)
        set(disciplinesAtom, undefined)
        set(disciplineSelectedAtom, undefined)
        // close MyCloe
        set(_openMyCloeAtom, false)
        set(isHoursAndMinutesNowAtom, true)
        break
      case 'UPDATE':
        set(scheduleRequestAtom, {
          ...scheduleRequest,
          [action.payload.key]: action.payload.value
        })
        break
    }
  }
)

type ResourceSelectedAtomActions<ResourceType> =
  | { type: 'ADD'; payload: ResourceType; openMyCloe: boolean }
  | { type: 'RESET'; payload?: undefined }
  | { type: 'REMOVE'; payload: ResourceType }

export const selectedResourcesAtom = atom(
  get => get(_selectedResourcesAtom),
  (get, set, action: ResourceSelectedAtomActions<IResourceResponse>) => {
    const actionResourceId = action.payload?.resourceId

    const resourcesSelected = get(_selectedResourcesAtom)
    const resourceFound = resourcesSelected.find(
      r => r.resourceId === actionResourceId
    )
    const resourceFiltered = resourcesSelected.filter(
      r => r.resourceId !== actionResourceId
    )

    switch (action.type) {
      case 'RESET':
        // used to reset the atom when leave screen with MyCloe
        return set(_selectedResourcesAtom, [])
      case 'ADD':
        // only update if resource is not found
        if (!resourceFound) {
          set(_selectedResourcesAtom, [...resourcesSelected, action.payload])
        }
        // open MyCloe with share tab selected
        set(_openMyCloeAtom, action.openMyCloe)
        set(myCloeLibraryContentTabIndexAtom, 1)
        break
      case 'REMOVE':
        set(_selectedResourcesAtom, [...resourceFiltered])
        break
    }
  }
)

export const selectedArticlesAtom = atom(
  get => get(_selectedArticlesAtom),
  (get, set, action: ResourceSelectedAtomActions<IArticleResponse>) => {
    const actionResourceId = action.payload?.articleId

    const resourcesSelected = get(_selectedArticlesAtom)
    const resourceFound = resourcesSelected.find(
      r => r.articleId === actionResourceId
    )
    const resourceFiltered = resourcesSelected.filter(
      r => r.articleId !== actionResourceId
    )

    switch (action.type) {
      case 'RESET':
        // used to reset the atom when leave screen with MyCloe
        return set(_selectedArticlesAtom, [])
      case 'ADD':
        // only update if resource is not found
        if (!resourceFound) {
          set(_selectedArticlesAtom, [...resourcesSelected, action.payload])
        }
        // open MyCloe with share tab selected
        set(_openMyCloeAtom, action.openMyCloe)
        set(myCloeLibraryContentTabIndexAtom, 1)
        break
      case 'REMOVE':
        set(_selectedArticlesAtom, [...resourceFiltered])
        break
    }
  }
)
