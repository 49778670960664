import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxs,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.lightBase,
    padding: `0 ${theme.spacingInset.xs} ${theme.spacingInset.lg} !important`,
    borderRadius: theme.borderRadius.sm,

    gap: theme.spacingStack.sm,

    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    '&.MuiDialogContent-root::-webkit-scrollbar': {
      width: '30px'
    },
    '&.MuiDialogContent-root::-webkit-scrollbar-thumb': {
      background: '#C4C4C4',
      borderRadius: '40px',
      backgroundClip: 'content-box',
      border: 'solid 10px transparent'
    }
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacingStack.xxs,
    flexWrap: 'wrap'
  },
  loading: {
    width: '100%'
  },
  button: {
    background: `${theme.colorBrand.medium} !important`,
    color: theme.colors.neutral.lightBase,
    padding: `${theme.spacingSquish.xs}`,
    height: '48px !important',
    border: 'none !important'
  },
}))
