import React, { useCallback, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { Grid, Theme, useTheme } from '@mui/material'
import { InputTime, InputDate } from 'components/common'
import {
  scheduleRequestAtom,
  updateStartDateAtom,
  isHoursAndMinutesNowAtom,
  isInvalidStartDateAtom,
  isValidStartDateTimeAtom
} from '../LibraryContentShare/atomStore'
import { setToLocaleUTC } from 'utils/date'
import { createStyles, makeStyles } from '@mui/styles'
import { useUpdateAtom } from 'jotai/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoText: {
      fontFamily: theme.font.fontFamily,
      lineHeight: theme.font.lineHeight.xs,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colorBrand.medium
    },
    dateLabel: {
      fontFamily: theme.font.fontFamily,
      lineHeight: theme.font.lineHeight.xs,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.bold,
      color: theme.colorBrand.medium
    },
    inputDate: {
      '& .MuiOutlinedInput-input': {
        fontWeight: `${theme.font.fontWeight.regular} !important`
      }
    },
    nowButton: {
      position: 'absolute',
      bottom: '5px',
      right: '16px',
      // width: '30px',
      color: theme.colorBrand.darkest,
      fontWeight: theme.font.fontWeight.medium,
      fontSie: theme.font.fontSize.xs,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })
)

interface ITimePickerToolbarProps {
  setOpenPicker: React.Dispatch<React.SetStateAction<boolean>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
}

interface IDateSelectProps {
  dataTestId?: {
    startDate: string
    startTime: string
  }
}

export const DateSelect = (props: IDateSelectProps) => {
  const [isHoursAndMinutesNow, setHoursAndMinutesToNow] = useAtom(
    isHoursAndMinutesNowAtom
  )
  const setStartDate = useUpdateAtom(updateStartDateAtom)
  const [schedule] = useAtom(scheduleRequestAtom)
  const setIsStartDateInvalid = useUpdateAtom(isInvalidStartDateAtom)
  const setIsValidStartDateTime = useUpdateAtom(isValidStartDateTimeAtom)
  const { schoolPeriod } = useStore()
  const minDateSchoolPeriod = schoolPeriod
    ? new Date(setToLocaleUTC(schoolPeriod?.start_date))
    : undefined
  const maxDateSchoolPeriod = schoolPeriod
    ? new Date(setToLocaleUTC(schoolPeriod?.end_date))
    : undefined

  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  const startDate = useMemo(
    () => schedule?.start_date ?? dayjs().toDate(),
    [schedule?.start_date]
  )

  const defaultVisionDataTestIds = {
    startDate: 'event_without_expedition_event_start_date',
    startTime: 'event_without_expedition_event_start_time'
  }

  const dataTestIds = props?.dataTestId ?? defaultVisionDataTestIds

  useEffect(() => {
    return () => {
      // reseting atom
      setIsValidStartDateTime(true)
    }
  }, [])

  const updateHourAndMinute = (
    newDate: dayjs.Dayjs,
    previousDate: Date | undefined
  ) => {
    return dayjs(previousDate).set({
      hour: newDate.hour(),
      minute: newDate.minute()
    })
  }

  const updateDayMonthYear = (
    newDate: dayjs.Dayjs,
    previousDate: Date | undefined
  ) => {
    return dayjs(previousDate).set({
      year: newDate.year(),
      month: newDate.month(),
      date: newDate.date()
    })
  }

  const getUpdatedFormattedDate = (
    newDate: Date,
    previousDate: Date | undefined | 'now',
    updateType: 'day' | 'hour-minute'
  ) => {
    // componente de hora em alguns cenários com causa incerta não atualiza valor interno de data, apenas de hora.
    // por isso foram separadas responsabilidades de alteração de apenas suas partes respectivas da data para cada componente de hora e data.
    if (previousDate === 'now') return

    const unformattedDate = dayjs(newDate)
    if (updateType === 'hour-minute') {
      return updateHourAndMinute(unformattedDate, previousDate).toDate()
    } else {
      return updateDayMonthYear(unformattedDate, previousDate).toDate()
    }
  }

  const handleChangeStartDate = useCallback(
    (newDate: Date | null, updateType: 'day' | 'hour-minute') => {
      if (!newDate || !dayjs(newDate).isValid()) return

      const formattedNewDate = getUpdatedFormattedDate(
        newDate,
        schedule?.start_date,
        updateType
      )
      setHoursAndMinutesToNow(false)
      setStartDate(formattedNewDate)
    },
    [schedule]
  )

  const TimePickerToolbar: React.FC<ITimePickerToolbarProps> = ({
    setOpenPicker,
    setInputValue
  }) => (
    <p
      data-testid='release_immediately_now'
      className={classes.nowButton}
      onClick={() => {
        setStartDate('now')
        setOpenPicker(false)
        setHoursAndMinutesToNow(true)
        setInputValue('')
      }}
    >
      {t('Agora')}
    </p>
  )

  return (
    <>
      <Grid
        container
        sx={{
          padding: theme.spacingSquish.nano,
          paddingY: '24px',
          paddingTop: '30px',
          borderTop: `solid 1px ${theme.colors.neutral.light10}`,
          borderBottom: `solid 1px ${theme.colors.neutral.light10}`,
          display: 'grid',
          gridTemplateColumns: '1fr'
        }}
        rowGap={'16px'}
      >
        <Grid item display={'grid'} rowGap={'16px'}>
          <span className={classes.dateLabel}>{t('Início')}</span>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputDate
                label={t('Dia')}
                initialDate={startDate === 'now' ? dayjs().toDate() : startDate}
                minDate={minDateSchoolPeriod}
                maxDate={maxDateSchoolPeriod}
                onChangeDate={date => handleChangeStartDate(date, 'day')}
                updateHoursMinutes={false}
                dataTestid={dataTestIds.startDate}
                onError={reason => {
                  if (!reason || reason === 'minDate') {
                    setIsStartDateInvalid(false)
                    return
                  }
                  if (reason === 'invalidDate') setIsStartDateInvalid(true)
                }}
                forceDateRefresh
              />
            </Grid>
            <Grid item xs={6}>
              <InputTime
                label={t('Horário')}
                onChange={date => handleChangeStartDate(date, 'hour-minute')}
                inputTime={
                  isHoursAndMinutesNow
                    ? null
                    : startDate === 'now'
                      ? dayjs().toDate()
                      : startDate
                }
                dataTestid={dataTestIds.startTime}
                defaultTimeIsNow
                ToolbarComponent={TimePickerToolbar}
                isValid={setIsValidStartDateTime}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
