import { useEffect, useState, useMemo } from 'react'
import { Grid, useTheme } from '@mui/material'
import useStyle, { DesktopContent, MobileContent } from './style'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { IGradeResponse, IGradeTypesResponse } from 'services/types/taught-disciplines'
import { Button, Tag } from 'components/design-system'
import AddIcon from '@mui/icons-material/Add'
import { LoadingWrapper, EmptyStateComponent } from 'components/common'
import { isActiveModalAtom } from './atomStore'
import { useAtom, atom } from 'jotai'
import { ModalClassesDisciplines } from './components/ModalClassesDisciplines'
import { getGradeTypesByProgram, getGradeFromGradeType } from 'services/grade'
import { BusinessError } from 'navigation/BusinessError'
import SubscriptionCard from 'components/common/SubscriptionCard'
import { MY_CLASSES, ROOT } from 'navigation/CONSTANTS'
import { timeout } from 'utils/timeout'
import Analytics from 'utils/analytics'
import { ReactComponent as AvatarWithCrossedArms } from 'assets/avatar-with-crossed-arms.svg'
import CloeSuperToggle, { Enabled, Disabled } from 'components/cloeSuperToggle'

export const gradeTypesAtom = atom<IGradeTypesResponse[]>([])
export const selectedGradeTypeAtom = atom<IGradeTypesResponse | null>(null)
export const gradesAtom = atom<IGradeResponse[] | null>(null)
export const selectedGradeAtom = atom<IGradeResponse | null>(null)
export const updatedTaughtAtom = atom<boolean>(false)

export const TaughtDisciplines: React.FC = () => {
  const { t } = useTranslation()
  const { profile, session, schoolPeriod, setSchoolPeriod } = useStore()
  const theme = useTheme()
  const history = useHistory()

  // stores
  const [gradeTypes, setGradeTypes] = useAtom(gradeTypesAtom)
  const [selectedGradeType, setSelectedGradeType] = useAtom(selectedGradeTypeAtom)
  const [grades, setGrades] = useAtom(gradesAtom)
  const [, setSelectedGrade] = useAtom(selectedGradeAtom)
  const [isActiveModal, setIsActiveModal] = useAtom(isActiveModalAtom)
  const [updateTaught, setUpdateTaught] = useAtom(updatedTaughtAtom)

  // states
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const getGradeTypesData = async () => {
    const response = await getGradeTypesByProgram()
    if (response.success) {
      if (response.data.length) {
        const gradeTypesFiltered = response.data.filter(gradeType => gradeType.classes_count)
        setGradeTypes(gradeTypesFiltered)

        // seleciona o primeiro grade type
        void selectGradeType(response.data[0])
      } else {
        setHasResponseError(true)
        setErrorMessage(t('Não foi possível encontrar nenhum Segmento.'))
      }
    } else {
      setHasResponseError(true)
      setErrorMessage(t('Houve um erro ao tentar buscar os Segmentos.'))
    }
    setLoading(false)
  }

  const getGradeData = async (gradeTypeId: number) => {
    const response = await getGradeFromGradeType(gradeTypeId, 'current')
    let grades: IGradeResponse[] = []
    if (response.success) {
      grades = response.data.filter(grade => grade?.classes_count > 0)
    }
    setGrades(grades)
  }

  const selectGradeType = async (gradeType: IGradeTypesResponse) => {
    setSelectedGradeType(gradeType)
    // deixa grades null para forçar loading
    setGrades(null)
    await timeout(2)
    await getGradeData(gradeType.id)

    if (profile && gradeType) {
      Analytics.recordEventClick({
        name: 'segment',
        attributes: {
          ...profile?.analytics,
          segment_id: gradeType?.id,
          segment_name: gradeType?.name
        }
      })
    }
  }

  const selectGrade = async (grade: IGradeResponse) => {
    setSelectedGrade(grade)
    setIsActiveModal(!isActiveModal)

    if (profile && grade && selectedGradeType) {
      Analytics.recordEventClick({
        name: 'add_group',
        attributes: {
          ...profile?.analytics,
          segment_id: selectedGradeType?.id,
          segment_name: selectedGradeType?.name,
          grade_id: grade?.id,
          grade_name: grade?.name
        }
      })
    }
  }

  const checkDisciplines = (grade: IGradeResponse) => {
    return grade.classes?.find(cur => cur.disciplines?.length)
  }

  const titleButtonClass = (grade: IGradeResponse) => {
    const checkDisciplines = grade.classes?.find(cur => cur.disciplines?.length)
    if (selectedGradeType?.code !== 'multigrade') {
      if (checkDisciplines) {
        return t('Editar turmas')
      }
      return <><AddIcon sx={{ fontSize: '24px' }} />{t('Adicionar turmas')}</>
    } else {
      if (checkDisciplines) {
        return t('Editar componentes')
      }
      return <><AddIcon sx={{ fontSize: '24px' }} />{t('Adicionar componentes')}</>
    }
  }

  const hasTaughtDisciplines = useMemo(() => {
    // TODO: por vezes não está pegando o mais atualizado da session, algum delay no set da userSession?
    if (session?.subscriptions) {
      return session.subscriptions
        .filter(subscription => subscription.user_school_profile && subscription.user_school_profile.id === profile?.id)
        .reduce((acc, subscription) => acc + ~~subscription.taught_disciplines.length, 0) > 0
    }
    return false
  }, [session])

  const descriptionTitle = useMemo(() => {
    if (hasTaughtDisciplines) {
      return t('Componentes curriculares')
    }

    return t('Configure as turmas que você leciona')
  }, [hasTaughtDisciplines])

  const segmentTitle = useMemo(() => {
    if (hasTaughtDisciplines) {
      return t('Selecione o segmento')
    }

    return t('Primeiro selecione o segmento em que atua')
  }, [hasTaughtDisciplines])

  useEffect(() => {
    // fecha modal por conta de bug de reload
    setIsActiveModal(false)
    setLoading(true)
    void getGradeTypesData()
  }, [])

  useEffect(() => {
    if (!isActiveModal && updateTaught && selectedGradeType) {
      setUpdateTaught(false)
      void selectGradeType(selectedGradeType)
    }
  }, [updateTaught])

  useEffect(() => {
    updateSelectedSchoolPeriod()
  }, [schoolPeriod])

  const updateSelectedSchoolPeriod = () => {
    if (schoolPeriod?.current === true) return

    const availableSchoolPeriods = profile?.school?.school_periods ?? []
    const currentSchoolPeriod = availableSchoolPeriods.find(period => period.current)

    if (!currentSchoolPeriod) return

    setSchoolPeriod(currentSchoolPeriod)
  }

  const classes = useStyle({ hasTaughtDisciplines })

  const notSetSchoolPeriod = Boolean(!grades?.length) && Boolean(!gradeTypes?.length) && !loading

  if (!profile) return <BusinessError error={t('Falha ao buscar profile')} />

  if (schoolPeriod?.current === false) {
    return (
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems='center'
        className={classes.container}
        sx={{
          marginTop: {
            xs: 0
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacingInline.xxxs,
            marginLeft: '0px',
            display: 'block'
          }
        }}
      >
        <BusinessError error={t('Não é possivel editar uma turma não corrente.')}>
          <br />
          <Button
            variant='primary'
            onClick={() => history.push('/')}
          >
            <span>
              {t('Voltar')}
            </span>
          </Button>
        </BusinessError>
      </Grid>
    )
  }

  if (hasResponseError) {
    return (
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems='center'
        className={classes.container}
        sx={{
          marginTop: {
            xs: 0
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacingInline.xxxs,
            marginLeft: '0px',
            display: 'block'
          }
        }}
      >
        <BusinessError error={errorMessage}>
          <br />
          <Button
            variant='primary'
            onClick={() => window.location.reload()}
          >
            <span>
              {t('Tentar novamente')}
            </span>
          </Button>
        </BusinessError>
      </Grid>
    )
  }

  if (notSetSchoolPeriod) {
    return (
      <Grid container p={2} className={classes.notFoundContainer}>
        <EmptyStateComponent
          ErrorAvatar={<AvatarWithCrossedArms />}
          title={t('O ano escolar selecionado ainda não foi configurado')}
          errorMessage={t('Solicite a configuração das turmas à coordenação da sua escola.')}
          maxMessageWidth='500px'
        />
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      className={classes.container}
      sx={{
        marginTop: {
          xs: 0
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '0px',
          display: 'block'
        }
      }}
    >
      <Grid item xs={12} lg={3} className={classes.descriptionContent}>
        <div tabIndex={0} className={classes.descriptionTitle}>
          {descriptionTitle}
        </div>
        {hasTaughtDisciplines && (
          <div tabIndex={0} className={classes.descriptionSubtitle}>
            {t('Selecione os componentes dentro das turmas que você leciona')}
          </div>
        )}
      </Grid>
      <Grid sx={{ padding: '32px', background: 'white' }} item xs={12} lg={9}>
        <MobileContent>
          <Grid container>
            <Grid item xs={12} className={classes.schoolTitle} textAlign='right'>
              {t('Escola atual')}
              <Grid item className={classes.schoolName}>
                <b>{profile?.school?.name}</b>
              </Grid>
            </Grid>
            <Grid item sx={{ fontSize: theme.font.fontSize.lg, color: theme.colors.neutral.dark20, fontWeight: theme.font.fontWeight.medium }} xs={12} >
              {segmentTitle}
            </Grid>
          </Grid>
        </MobileContent>
        <DesktopContent>
          <Grid container sx={{ marginBlock: theme.spacingStack.xxxs, background: 'white' }}>
            <Grid item className={classes.schoolTitle} tabIndex={0} xs={8}>
              {segmentTitle}
            </Grid>
            <Grid item tabIndex={0} xs={4} sx={{ fontSize: theme.font.fontSize.xxxs }} textAlign='right'>
              {t('Escola atual')}
              <Grid item tabIndex={0} className={classes.schoolName}>
                <b>{profile?.school?.name}</b>
              </Grid>
            </Grid>
          </Grid>
        </DesktopContent>
        <Grid container mt={1} gap={theme.spacingInline.nano} spacing={1}>
          {
            gradeTypes
              .map((gradeType, idx) => (
                <Grid item key={gradeType.id} data-testid='curricular_component_segment'>
                  <Tag
                    tabIndex={idx}
                    key={gradeType.id}
                    background={selectedGradeType && selectedGradeType?.id === gradeType.id ? '#BFB2D3A3' : undefined}
                    onClick={() => { void selectGradeType(gradeType) }}
                    className={classes.gradeTypeTag}
                  >
                    {gradeType.name}
                  </Tag>
                </Grid>
              ))
          }
        </Grid>
        <LoadingWrapper type='linear' loading={grades === null}>
          {!hasTaughtDisciplines
            ? (
              <Grid container >
                <Grid item xs={12} className={classes.subTitle}>
                  {t('Agora verifique os anos em que atua e adicione as turmas onde leciona')}:
                </Grid>
              </Grid>
            )
            : <></>
          }
          {
            !loading && (
              <Grid item sx={{ pt: hasTaughtDisciplines ? 5 : 0 }}>
                {
                  grades?.length
                    ? grades.map((grade, i) => (
                      <Grid item sx={{ pb: 5 }}>
                        <div className={classes.yearTitle}>
                          {grade.name}
                        </div>
                        <Grid item display='flex' flexWrap='wrap' gap={theme.spacingInline.xxxs} mb={3} data-testid='discipline_selection'>
                          {
                            grade.classes?.map((_class, index) =>
                              _class?.disciplines?.length
                                ? <SubscriptionCard
                                  key={index}
                                  classId={_class.id}
                                  className={_class.name}
                                  disciplines={_class.disciplines} />
                                : <></>)
                          }
                        </Grid>
                        <Button
                          sx={{ marginTop: '10px', lineHeight: '24px', gap: '12px' }}
                          variant='outlined'
                          key={`${i}.button`}
                          onClick={() => { void selectGrade(grade) }}
                          data-testid={checkDisciplines(grade) ? 'curricular_component_group_edit_classes' : 'curricular_component_group_add'}
                        >
                          {titleButtonClass(grade)}
                        </Button>
                      </Grid>))
                    : (
                      <Grid className={classes.notFoundContainer}>
                        <EmptyStateComponent
                          ErrorAvatar={<AvatarWithCrossedArms />}
                          title={t('A lista de turmas está vazia.')}
                          errorMessage={t('Solicite a configuração das turmas à coordenação da sua escola.')}
                          maxMessageWidth='500px'
                        />
                      </Grid>
                    )
                }
                <div className={classes.boxFooterButtons}>
                  <CloeSuperToggle>
                    <Enabled>
                      <Button disabled={!hasTaughtDisciplines} variant='primary' onClick={() => history.push(MY_CLASSES)} data-testid='curricular_component_finish_editing'>
                        {t('Finalizar')}
                      </Button>
                    </Enabled>
                    <Disabled>
                      <Button disabled={!hasTaughtDisciplines} variant='primary' onClick={() => history.push(ROOT)} data-testid='curricular_component_finish_editing'>
                        {t('Finalizar')}
                      </Button>
                    </Disabled>
                  </CloeSuperToggle>
                </div>
              </Grid>
            )
          }
        </LoadingWrapper>
        <ModalClassesDisciplines />
      </Grid>
    </Grid>
  )
}

export default TaughtDisciplines
