import React, { useMemo, useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import useStyles from './style'
import { DesktopTimePicker } from '@mui/lab'
import { TextField as TextFieldMUI } from '@mui/material'
import dayjs from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'

interface IInputTimeProps {
  label?: string
  inputTime?: Date | null
  onChange: (date: Date | null) => void
  isValid?: (isValid: boolean) => void
  dataTestid?: string | null
  defaultTimeIsNow?: boolean
  ToolbarComponent?: React.JSXElementConstructor<any> | null
}

export const InputTime = ({
  label,
  inputTime = null,
  onChange,
  dataTestid,
  defaultTimeIsNow = false,
  ToolbarComponent,
  isValid
}: IInputTimeProps) => {
  dayjs.extend(objectSupport)
  const [isInputFocused, setInputFocused] = useState(false)
  const [inputValue, setInputValue] = useState<string | null>('')
  const [isValidInput, setIsValidInput] = useState(true)
  const [openPicker, setOpenPicker] = useState(false)
  const showNowAsLabel = useMemo(
    () => !inputTime && !inputValue && !isInputFocused && defaultTimeIsNow,
    [inputTime, inputValue, isInputFocused, defaultTimeIsNow]
  )
  const inputRef = React.createRef<HTMLDivElement>()
  const classes = useStyles()

  const handleClose = () => {
    setOpenPicker(false)
  }

  const handleDataTaging = () => {
    inputRef?.current?.click()
  }

  const isValidTime = (time: string) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/
    return regex.test(time)
  }

  const handleTimeChange = (newValue: Date | null) => {
    if (newValue) {
      const formattedTime = dayjs(newValue).format('HH:mm')
      const _isValidTime = isValidTime(formattedTime)
      setIsValidInput(_isValidTime)
      setInputValue(formattedTime)
      onChange(newValue)

      if (isValid) {
        isValid(_isValidTime)
      }
    }
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestid} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          ampm={false}
          openTo='hours'
          views={['hours', 'minutes']}
          inputFormat='HH:mm'
          open={openPicker}
          mask='__:__'
          label={showNowAsLabel ? 'Agora' : label}
          value={inputTime}
          onChange={handleTimeChange} // Interceptamos a mudança
          onOpen={() => setOpenPicker(true)}
          onClose={handleClose}
          renderInput={params => (
            <TextFieldMUI
              {...params}
              value={inputValue}
              onChange={event => {
                const newTime = event.target.value
                if (isValidTime(newTime)) {
                  setInputValue(newTime)
                }
              }}
              className={classes.selector}
              onClick={handleDataTaging}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              error={!isValidInput}
              helperText={!isValidInput ? 'Horário inválido' : ''}
            />
          )}
          showToolbar={defaultTimeIsNow}
          ToolbarComponent={() => (
            // @ts-expect-error
            <ToolbarComponent
              setOpenPicker={setOpenPicker}
              setInputValue={setInputValue}
            />
          )}
        />
      </LocalizationProvider>
    </>
  )
}
